import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import { Input, Col, Row, Button } from 'antd';
import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined, KeyOutlined, LockOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { isUndefined } from '../../../utils/common_utils';
import StoreState, { LoginParams } from '../../../types/securityTypes';
import { getThunkDispatch } from '../../../redux/store';
import { getEmplLogin } from '../../../redux/authActions';

import styles from '../../Design.module.css';

const Login = () => {
    const navigation = useNavigate();
    
    const { loading, token } = useSelector((state: StoreState) => state.auth);

    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    useEffect(() => {
        if (token) navigation('/secured/dashboard');
    }, [token, navigation]);

    const logIn = async () => {
        if (isUndefined(email) || isUndefined(password)) return;

        const data: LoginParams = {email: email!, password: password!};
        getThunkDispatch()(getEmplLogin()(data));
    }

    return (
        <div className={styles.LoginWrapper} onKeyDownCapture={event => event.key === "Enter" ? logIn() : null}>
            <div className={styles.LoginAuth}>
                <LockOutlined />
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Input 
                            size="large" 
                            placeholder="email" 
                            prefix={<UserOutlined />} 
                            value={email} 
                            onChange={(event) => setEmail(event.target.value)} />
                    </Col>
                    <Col span={24}>
                        <Input.Password 
                            size="large" 
                            prefix={<KeyOutlined />} 
                            placeholder="password" 
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                            value={password}
                            onChange={(event) => setPassword(event.target.value)} />
                    </Col>
                    <Col span={24}>
                        <Button type="primary" size="large" loading={loading} onClick={logIn} block>Přihlásit se</Button>
                    </Col>
                </Row>
            </div>
            <small>JVBros © 2024 | Version 3.0.1</small>
        </div>
    )
}

export default Login;